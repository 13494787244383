import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

const infinitExpirationDate = new Date(new Date().setYear(2050))

export default class extends Controller {
  static values = {
    name: String,
    value: String,
    expirationTimestamp: {
      type: Number,
      default: Infinity
    }
  }

  setCookie() {
    let cookieSettings = {}
    if (this.expirationTimestampValue) {
      const expirationDate = this.expirationTimestampValue === Infinity ? infinitExpirationDate : new Date(this.expirationTimestampValue)
      cookieSettings = { expires: expirationDate }
    }
    Cookies.set(this.nameValue, this.valueValue, cookieSettings)
  }
}
